<template>
  <div class="landing-page-features-editor">
    <v-form class="pb-6">
      <div class="inputs pl-6 pt-6">
        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.menuTitle"
            solo
            rounded
            hide-details
            label="Menu"
            :disabled="!configurations.menuTitle.visible"
            @input="onChange('menuTitle', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.menuTitle.visible"
            inset
            class="ml-6"
            @change="toggle('menuTitle', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.title"
            solo
            rounded
            hide-details
            label="Título"
            :disabled="!configurations.title.visible"
            @input="onChange('title', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.title.visible"
            inset
            class="ml-6"
            @change="toggle('title', $event)"
          ></v-switch>
        </div>

        <br />

        <div>
          <div class="d-flex justify-space-between mb-3">
            <span class="subtitle-2"> Recursos </span>
            <v-btn
              small
              plain
              :ripple="false"
              color="success darken-1"
              @click="featureItemDialog.display = true"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>

          <v-simple-table
            v-if="theSection.features && theSection.features.length > 0"
            fixed-header
          >
            <template #default>
              <draggable tag="tbody" :list="theSection.features" handle=".drag">
                <tr
                  v-for="(feature, index) in theSection.features"
                  :key="feature.title"
                >
                  <td class="td-drag pl-0">
                    <v-btn small plain :ripple="false" class="drag">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>
                  </td>
                  <td
                    class="td-title px-0"
                    @dblclick="editFeatureItem(feature, index)"
                  >
                    <div>
                      {{ feature.title }}
                    </div>
                  </td>
                  <td class="td-actions pr-0">
                    <v-btn
                      small
                      plain
                      :ripple="false"
                      @click="editFeatureItem(feature, index)"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      plain
                      :ripple="false"
                      @click="markFeatureItemForDelete(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-simple-table>
          <div v-else class="text-center grey--text">
            <small>Ainda não há recursos</small>
          </div>

          <v-dialog v-model="deleteFeatureItemDialog.display" max-width="350">
            <v-card>
              <v-card-title class="pb-0">Tem Certeza?</v-card-title>
              <v-card-subtitle class="mt-0 pb-2"></v-card-subtitle>

              <v-divider></v-divider>

              <v-card-text class="mt-3">
                Se você continuar, este recurso será removido da página inicial
                do sistema.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  @click="deleteFeatureItemDialog.display = false"
                >
                  Desistir
                </v-btn>
                <v-btn
                  color="error darken-1"
                  rounded
                  text
                  @click="deleteFeatureItem"
                >
                  Remover
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="featureItemDialog.display" max-width="350">
            <v-card>
              <v-card-title class="pb-0">Novo Recurso </v-card-title>
              <v-card-subtitle class="mt-0 pb-2"
                >O recurso será adicionado à grade de recursos na página
                inicial</v-card-subtitle
              >

              <v-divider></v-divider>

              <v-card-text class="mt-3">
                <v-text-field
                  v-model="featureItemDialog.feature.title"
                  solo
                  rounded
                  hide-details
                  label="Título"
                ></v-text-field>
              </v-card-text>

              <v-card-text>
                <v-textarea
                  v-model="featureItemDialog.feature.description"
                  solo
                  hide-details
                  label="Descrição"
                ></v-textarea>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn rounded text @click="featureItemDialog.display = false">
                  Fechar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  rounded
                  text
                  @click="onFeatureItemDialogSave"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>

      <div class="d-flex flex-row-reverse pt-6">
        <v-menu
          v-model="menuDelete"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="error"
              rounded
              text
              class="text-capitalize mr-4"
              >Remover</v-btn
            >
          </template>
          <v-card max-width="300">
            <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

            <v-card-text>
              Se você continuar, esta seção será removida da página inicial do
              sistema.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                text
                small
                class="py-4"
                @click="menuDelete = false"
              >
                Desistir
              </v-btn>
              <v-btn
                color="error darken-1"
                rounded
                text
                small
                class="py-4"
                @click="remove"
              >
                Remover
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </v-form>
  </div>
</template>

<script>
import { get as _get, set as _set } from "lodash";
import draggable from "vuedraggable";

import {
  Features,
  FeatureItem,
} from "components/landing-page/LandingPageFeatures";

export default {
  name: "LandingPageEditorFeaturesEditor",
  components: { draggable },
  props: {
    section: {
      type: Features,
      required: true,
    },
  },
  data: () => ({
    featureItemDialog: {
      display: false,
      feature: {
        index: -1,
        title: undefined,
        description: undefined,
      },
    },
    deleteFeatureItemDialog: {
      display: false,
      feature: { index: -1 },
    },
    menuDelete: false,
    theSection: {},
    configurations: {
      menuTitle: {
        visible: true,
        value: undefined,
      },
      title: {
        visible: true,
        value: undefined,
      },
    },
  }),
  watch: {
    "featureItemDialog.display": {
      handler(displayed) {
        if (!displayed) {
          this.onFeatureItemDialogClose();
        }
      },
    },
    "deleteFeatureItemDialog.display": {
      handler(displayed) {
        if (!displayed) {
          this.onDeleteFeatureItemDialogClose();
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.theSection = this.section;

      this.confitureItem("menuTitle");
      this.confitureItem("title");
    },
    confitureItem(modelPath) {
      const model = _get(this.theSection, modelPath);
      const configuration = _get(this.configurations, modelPath);

      configuration.visible = typeof model === "string" && model.length > 0;
      configuration.value = model;
    },
    remove() {
      this.$emit("on-remove", this.section);
    },
    toggle(modelPath, visible) {
      const configuration = _get(this.configurations, modelPath);
      configuration.visible = visible;

      if (visible) {
        _set(this.theSection, modelPath, configuration.value);
      } else {
        _set(this.theSection, modelPath, undefined);
      }
    },
    onChange(convigurationPath, value) {
      _set(this.configurations, convigurationPath + ".value", value);
    },
    editFeatureItem(feature, index) {
      this.featureItemDialog.feature.index = index;
      this.featureItemDialog.feature.title = feature.title;
      this.featureItemDialog.feature.description = feature.description;

      this.featureItemDialog.display = true;
    },
    markFeatureItemForDelete(index) {
      this.deleteFeatureItemDialog.feature.index = index;
      this.deleteFeatureItemDialog.display = true;
    },
    deleteFeatureItem() {
      this.theSection.features.splice(
        this.deleteFeatureItemDialog.feature.index,
        1
      );

      this.deleteFeatureItemDialog.display = false;
    },
    onDeleteFeatureItemDialogClose() {
      this.deleteFeatureItemDialog.feature = {
        index: -1,
      };
    },
    onFeatureItemDialogClose() {
      this.featureItemDialog.feature = {
        index: -1,
        title: undefined,
        description: undefined,
      };
    },
    onFeatureItemDialogSave() {
      try {
        if (this.featureItemDialog.feature.index < 0) {
          this.theSection.features.push(
            new FeatureItem({ ...this.featureItemDialog.feature })
          );
        } else {
          this.$set(
            this.theSection.features,
            this.featureItemDialog.feature.index,
            new FeatureItem({ ...this.featureItemDialog.feature })
          );
        }

        this.featureItemDialog.display = false;
      } catch (error) {
        console.error("Erro ao adicionar o recurso.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.td-drag {
  width: 82px;
}
.td-title {
  cursor: pointer;
  width: auto;
  max-width: 101px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.td-actions {
  width: 132px;
}
</style>