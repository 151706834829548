var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "elevation": "1"
    }
  }, [_vm.editing.section ? _c('v-btn', {
    attrs: {
      "rounded": "",
      "plain": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearEdition.apply(null, arguments);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _c('v-toolbar-title', {
    staticClass: "subtitle-2",
    domProps: {
      "textContent": _vm._s(_vm.title)
    }
  }), _c('v-spacer'), !_vm.editing.section ? _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "rounded": "",
      "small": "",
      "outlined": "",
      "color": "success"
    },
    on: {
      "click": _vm.onSaveClick
    }
  }, [_vm._v("Salvar")]) : _vm._e(), !_vm.editing.section ? _c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "plain": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus-circle-outline")])], 1)];
      }
    }], null, false, 1983714284)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.sectionTypeConfigurations, function (configuration, index) {
    return _c('v-list-item', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.addSection(configuration);
        }
      }
    }, [_c('v-list-item-avatar', [_c('v-btn', {
      attrs: {
        "icon": "",
        "x-small": ""
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(configuration.icon)
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(configuration.title)
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": "",
        "plain": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), !_vm.editing.section ? _c('v-col', [_c('v-row', [_c('v-col', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('draggable', {
    staticClass: "list-group",
    attrs: {
      "tag": "ul",
      "list": _vm.landingPageData.sections,
      "handle": ".drag"
    }
  }, _vm._l(_vm.sectionConfigurationsList, function (configuration, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-avatar', {
      staticClass: "drag"
    }, [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-drag")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(configuration.title)
      }
    })], 1), _c('v-list-item-action', [_c('v-item-group', [_c('v-btn', {
      attrs: {
        "rounded": "",
        "plain": "",
        "ripple": "",
        "disabled": !configuration.editable
      },
      on: {
        "click": function ($event) {
          return _vm.editSectionAt(index);
        }
      }
    }, [configuration.editable ? _c('v-icon', [_vm._v("mdi-square-edit-outline")]) : _c('v-icon', [_vm._v("mdi-block-helper")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1) : _c('v-col', [_vm.sectionEditor ? _c(_vm.sectionEditor, {
    tag: "component",
    attrs: {
      "section": _vm.editing.section
    },
    on: {
      "on-remove": _vm.onRemoveSection
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }