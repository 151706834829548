var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "landing-page-cover-editor"
  }, [_c('v-form', {
    staticClass: "pb-6"
  }, [_c('div', {
    staticClass: "inputs pl-6 pt-6"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "URL da Imagem"
    },
    model: {
      value: _vm.theSection.coverSrc,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "coverSrc", $$v);
      },
      expression: "theSection.coverSrc"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "disabled": "",
      "inset": "",
      "input-value": true
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.configurations.dark.options,
      "item-text": "title",
      "item-value": "value",
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Claridade"
    },
    model: {
      value: _vm.theSection.dark,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "dark", $$v);
      },
      expression: "theSection.dark"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "input-value": true,
      "inset": "",
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "URL do Logotipo",
      "disabled": !_vm.configurations.logoSrc.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('logoSrc', $event);
      }
    },
    model: {
      value: _vm.theSection.logoSrc,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "logoSrc", $$v);
      },
      expression: "theSection.logoSrc"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('logoSrc', $event);
      }
    },
    model: {
      value: _vm.configurations.logoSrc.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.logoSrc, "visible", $$v);
      },
      expression: "configurations.logoSrc.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Título",
      "disabled": !_vm.configurations.title.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('title', $event);
      }
    },
    model: {
      value: _vm.theSection.title,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "title", $$v);
      },
      expression: "theSection.title"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('title', $event);
      }
    },
    model: {
      value: _vm.configurations.title.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.title, "visible", $$v);
      },
      expression: "configurations.title.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Informação",
      "disabled": !_vm.configurations.secondText.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('secondText', $event);
      }
    },
    model: {
      value: _vm.theSection.secondText,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "secondText", $$v);
      },
      expression: "theSection.secondText"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('secondText', $event);
      }
    },
    model: {
      value: _vm.configurations.secondText.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.secondText, "visible", $$v);
      },
      expression: "configurations.secondText.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Descrição",
      "disabled": !_vm.configurations.thirdText.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('thirdText', $event);
      }
    },
    model: {
      value: _vm.theSection.thirdText,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "thirdText", $$v);
      },
      expression: "theSection.thirdText"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('thirdText', $event);
      }
    },
    model: {
      value: _vm.configurations.thirdText.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.thirdText, "visible", $$v);
      },
      expression: "configurations.thirdText.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "URL do Logotipo",
      "disabled": !_vm.configurations.loginButton.title.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('loginButton.title', $event);
      }
    },
    model: {
      value: _vm.theSection.loginButton.title,
      callback: function ($$v) {
        _vm.$set(_vm.theSection.loginButton, "title", $$v);
      },
      expression: "theSection.loginButton.title"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('loginButton.title', $event);
      }
    },
    model: {
      value: _vm.configurations.loginButton.title.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.loginButton.title, "visible", $$v);
      },
      expression: "configurations.loginButton.title.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "URL de Acesso",
      "disabled": !_vm.configurations.loginButton.path.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('loginButton.path', $event);
      }
    },
    model: {
      value: _vm.theSection.loginButton.path,
      callback: function ($$v) {
        _vm.$set(_vm.theSection.loginButton, "path", $$v);
      },
      expression: "theSection.loginButton.path"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "disabled": "",
      "inset": "",
      "input-value": true
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex flex-row-reverse pt-6"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-y-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-capitalize mr-4",
          attrs: {
            "color": "error",
            "rounded": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Remover")])];
      }
    }]),
    model: {
      value: _vm.menuDelete,
      callback: function ($$v) {
        _vm.menuDelete = $$v;
      },
      expression: "menuDelete"
    }
  }, [_c('v-card', {
    attrs: {
      "max-width": "300"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Tem Certeza? ")]), _c('v-card-text', [_vm._v(" Se você continuar, esta seção será removida da página inicial do sistema. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.menuDelete = false;
      }
    }
  }, [_vm._v(" Desistir ")]), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "color": "error darken-1",
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v(" Remover ")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }