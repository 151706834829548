var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page--groups"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg",
      "loading": _vm.isSearching
    }
  }, [_c('v-col', [_c('v-row', [_c('v-col', {
    staticClass: "py-7"
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('v-text-field', {
    ref: "textSearch",
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "Pesquisar Grupo",
      "autocomplete": "off",
      "hide-details": "",
      "clearable": "",
      "solo": "",
      "rounded": ""
    },
    model: {
      value: _vm.textToSearch,
      callback: function ($$v) {
        _vm.textToSearch = $$v;
      },
      expression: "textToSearch"
    }
  }) : _c('v-autocomplete', {
    ref: "autocompleteSearch",
    attrs: {
      "search-input": _vm.textToSearch,
      "items": _vm.searchedItems,
      "item-text": "name",
      "clearable": "",
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify",
      "autocomplete": "off",
      "no-data-text": "Nenhum Grupo encontrado",
      "placeholder": "Pesquisar Grupo"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.textToSearch = $event;
      },
      "update:search-input": function ($event) {
        _vm.textToSearch = $event;
      },
      "change": function ($event) {
        return _vm.onSelectSearchedItem();
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (data) {
        return [[_c('v-list-item-icon', [_c('v-icon', {
          domProps: {
            "textContent": _vm._s(data.item.icon || 'border-none-variant')
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.displayName)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.textAutocomplete,
      callback: function ($$v) {
        _vm.textAutocomplete = $$v;
      },
      expression: "textAutocomplete"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "d-none d-md-flex"
  }), _c('v-row', {
    staticClass: "d-none d-md-flex"
  }, [_c('v-col', [_c('v-responsive', [_c('v-list', {
    staticClass: "overflow-auto",
    attrs: {
      "two-line": "",
      "max-height": "1000"
    }
  }, _vm._l(_vm.searchedItems, function (group, ui) {
    return _c('v-list-item', {
      key: ui
    }, [_c('v-list-item-icon', [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(group.icon || 'border-none-variant')
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(group.displayName)
      }
    }), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(group.name)
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "text": "",
        "rounded": ""
      },
      on: {
        "click": function ($event) {
          return _vm.selectGroup(group);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8",
      "xl": "9"
    }
  }, [_c('v-card', {
    staticClass: "group-details",
    class: {
      'hide-permissions': _vm.hidePermissions
    },
    attrs: {
      "rounded": "lg"
    }
  }, [_vm.selectedItem ? _c('v-toolbar', {
    attrs: {
      "dense": "",
      "elevation": "1"
    }
  }, [_c('v-chip', {
    staticClass: "ma-2",
    attrs: {
      "color": _vm.statusColor(_vm.selectedItem.active),
      "text-color": "white"
    }
  }, [_c('v-avatar', {
    staticClass: "darken-4",
    class: _vm.statusColor(_vm.selectedItem.active),
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.selectedItem.position)
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.statusLabel)
    }
  })], 1), _vm.selectedItem.locked ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "color": "grey"
          }
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-shield")])];
      }
    }], null, false, 247688801)
  }, [_vm._v(" Grupo Protegido ")]) : _vm._e(), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "plain": "",
            "ripple": false
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-cog-outline")])], 1)];
      }
    }], null, false, 1018401474)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "disabled": _vm.selectedItem.locked
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showEditDialog.apply(null, arguments);
      }
    }
  }, [_c('v-list-item-avatar', [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil-outline")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    domProps: {
      "textContent": _vm._s('Editar Grupo')
    }
  })], 1)], 1), _c('v-list-item', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showCreateDialog.apply(null, arguments);
      }
    }
  }, [_c('v-list-item-avatar', [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-plus-circle-outline")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    domProps: {
      "textContent": _vm._s('Novo Grupo')
    }
  })], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "disabled": _vm.selectedItem.locked
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.dialogDelete.show = true;
      }
    }
  }, [_c('v-list-item-avatar', [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    domProps: {
      "textContent": _vm._s('Remover Grupo')
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.selectedItem ? _c('v-card', {
    staticClass: "my-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "50"
    },
    domProps: {
      "textContent": _vm._s(_vm.selectedItem.icon || 'border-none-variant')
    }
  }), _c('br'), _c('span', {
    staticClass: "d-inline-block mt-1 font-weight-medium",
    domProps: {
      "textContent": _vm._s(_vm.selectedItem.displayName)
    }
  })], 1)]) : _vm._e(), _c('v-card', {
    staticClass: "mb-1 mb-lg-6 pa-5",
    attrs: {
      "rounded": "lg",
      "elevation": "1"
    }
  }, [_vm.selectedItem ? _c('v-list', {
    attrs: {
      "subheader": "",
      "two-line": "",
      "elevation": "1"
    }
  }, [_c('v-subheader', {
    staticClass: "ml-0",
    attrs: {
      "inset": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v("mdi-format-list-checks")]), _c('span', {
    staticClass: "break-spaces"
  }, [_vm._v("Permissões")])], 1), _vm._l(_vm.selectedItem.permissions, function (item) {
    return _c('v-list-item', {
      key: `${item.id}`,
      attrs: {
        "disabled": !_vm.selectedItem
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [item.icon ? _c('v-icon', {
      staticClass: "mr-3",
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    }) : _vm._e(), item.off ? _c('div', [_c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref3) {
          var on = _ref3.on,
              attrs = _ref3.attrs;
          return [_c('span', {
            staticClass: "break-spaces",
            domProps: {
              "textContent": _vm._s(item.displayName)
            }
          }), _c('v-icon', _vm._g(_vm._b({
            staticClass: "ml-2",
            attrs: {
              "small": "",
              "color": "grey"
            }
          }, 'v-icon', attrs, false), on), [_vm._v("mdi-shield-off")])];
        }
      }], null, true)
    }, [_vm._v(" Desativada ")])], 1) : _c('span', {
      staticClass: "break-spaces",
      domProps: {
        "textContent": _vm._s(item.displayName)
      }
    })], 1), _c('v-list-item-subtitle', {
      staticClass: "break-spaces",
      domProps: {
        "textContent": _vm._s(item.name)
      }
    })], 1), _c('v-list-item-action', [_c('v-switch', {
      attrs: {
        "disabled": item.locked,
        "loading": item.isSaving,
        "inset": ""
      },
      on: {
        "change": function ($event) {
          return _vm.savePermissionChange(item, item.active);
        }
      },
      model: {
        value: item.active,
        callback: function ($$v) {
          _vm.$set(item, "active", $$v);
        },
        expression: "item.active"
      }
    })], 1)], 1);
  })], 2) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    on: {
      "input": _vm.closeDialog
    },
    model: {
      value: _vm.dialog.show,
      callback: function ($$v) {
        _vm.$set(_vm.dialog, "show", $$v);
      },
      expression: "dialog.show"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5",
    domProps: {
      "textContent": _vm._s(_vm.dialog.title)
    }
  }), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-5 mt-5"
  }, [_c('v-form', {
    ref: "form",
    staticClass: "px-10",
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.dialog.isFormValid,
      callback: function ($$v) {
        _vm.$set(_vm.dialog, "isFormValid", $$v);
      },
      expression: "dialog.isFormValid"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "append-icon": _vm.dialog.data.icon,
      "id": "permission_icon",
      "placeholder": "Ícone",
      "label": "Ícone",
      "dense": "",
      "hint": "Qual o ícone da permissão?"
    },
    model: {
      value: _vm.dialog.data.icon,
      callback: function ($$v) {
        _vm.$set(_vm.dialog.data, "icon", $$v);
      },
      expression: "dialog.data.icon"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "id": "permission_display-name",
      "placeholder": "Nome de Exibição",
      "label": "Nome de Exibição",
      "dense": "",
      "hint": "Qual o nome será exibido?"
    },
    model: {
      value: _vm.dialog.data.displayName,
      callback: function ($$v) {
        _vm.$set(_vm.dialog.data, "displayName", $$v);
      },
      expression: "dialog.data.displayName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "id": "permission_name",
      "placeholder": "Nome",
      "label": "Nome",
      "dense": "",
      "hint": "Qual o nome da permissão?"
    },
    model: {
      value: _vm.dialog.data.name,
      callback: function ($$v) {
        _vm.$set(_vm.dialog.data, "name", $$v);
      },
      expression: "dialog.data.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-5",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-slider', {
    attrs: {
      "label": "Posição",
      "min": 1,
      "thumb-label": "always",
      "thumb-size": 24,
      "dense": ""
    },
    model: {
      value: _vm.dialog.data.position,
      callback: function ($$v) {
        _vm.$set(_vm.dialog.data, "position", $$v);
      },
      expression: "dialog.data.position"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "inset": "",
      "dense": "",
      "label": "Ativo"
    },
    model: {
      value: _vm.dialog.data.active,
      callback: function ($$v) {
        _vm.$set(_vm.dialog.data, "active", $$v);
      },
      expression: "dialog.data.active"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "default",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Cancelar ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.dialog.saveLabel)
    },
    on: {
      "click": _vm.save
    }
  })], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "300"
    },
    on: {
      "input": _vm.closeDialog
    },
    model: {
      value: _vm.dialogDelete.show,
      callback: function ($$v) {
        _vm.$set(_vm.dialogDelete, "show", $$v);
      },
      expression: "dialogDelete.show"
    }
  }, [_c('v-card', {
    attrs: {
      "max-width": "300"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Tem Certeza? ")]), _c('v-card-text', [_vm._v(" Se você continuar, todos os usuários que tiverem este grupo serão afetados. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialogDelete.show = false;
      }
    }
  }, [_vm._v(" Desistir ")]), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "color": "error darken-1",
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.remove(_vm.selectedItem);
      }
    }
  }, [_vm._v(" Remover ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }