var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page--permissions"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "landing-page-edition",
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "sticky"
  }, [_c('lp-editor', {
    attrs: {
      "landing-page-data": _vm.previewData
    },
    on: {
      "sections-updated": _vm.updateSections,
      "on-save": _vm.onSave
    }
  })], 1)]), _c('v-col', {
    staticClass: "landing-page-preview",
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8",
      "xl": "9"
    }
  }, [_c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('div', [_c('landing-page', {
    attrs: {
      "preview": true,
      "preview-data": _vm.previewData
    }
  })], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }