var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "landing-page-section-editor"
  }, [_c('v-form', {
    staticClass: "pb-6"
  }, [_c('div', {
    staticClass: "inputs pl-6 pt-6"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Menu",
      "disabled": !_vm.configurations.menuTitle.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('menuTitle', $event);
      }
    },
    model: {
      value: _vm.theSection.menuTitle,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "menuTitle", $$v);
      },
      expression: "theSection.menuTitle"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('menuTitle', $event);
      }
    },
    model: {
      value: _vm.configurations.menuTitle.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.menuTitle, "visible", $$v);
      },
      expression: "configurations.menuTitle.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "URL da Imagem",
      "disabled": !_vm.configurations.imageSrc.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('imageSrc', $event);
      }
    },
    model: {
      value: _vm.theSection.imageSrc,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "imageSrc", $$v);
      },
      expression: "theSection.imageSrc"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('imageSrc', $event);
      }
    },
    model: {
      value: _vm.configurations.imageSrc.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.imageSrc, "visible", $$v);
      },
      expression: "configurations.imageSrc.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.configurations.leftImage.positions,
      "item-text": "title",
      "item-value": "value",
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Posição da Imagem"
    },
    model: {
      value: _vm.theSection.leftImage,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "leftImage", $$v);
      },
      expression: "theSection.leftImage"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "input-value": true,
      "inset": "",
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Título",
      "disabled": !_vm.configurations.title.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('title', $event);
      }
    },
    model: {
      value: _vm.theSection.title,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "title", $$v);
      },
      expression: "theSection.title"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('title', $event);
      }
    },
    model: {
      value: _vm.configurations.title.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.title, "visible", $$v);
      },
      expression: "configurations.title.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center mt-2"
  }, [_c('v-textarea', {
    attrs: {
      "solo": "",
      "hide-details": "",
      "label": "Conteúdo",
      "disabled": !_vm.configurations.content.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('content', $event);
      }
    },
    model: {
      value: _vm.theSection.content,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "content", $$v);
      },
      expression: "theSection.content"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('content', $event);
      }
    },
    model: {
      value: _vm.configurations.content.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.content, "visible", $$v);
      },
      expression: "configurations.content.visible"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex flex-row-reverse pt-6"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-y-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-capitalize mr-4",
          attrs: {
            "color": "error",
            "rounded": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Remover")])];
      }
    }]),
    model: {
      value: _vm.menuDelete,
      callback: function ($$v) {
        _vm.menuDelete = $$v;
      },
      expression: "menuDelete"
    }
  }, [_c('v-card', {
    attrs: {
      "max-width": "300"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Tem Certeza? ")]), _c('v-card-text', [_vm._v(" Se você continuar, esta seção será removida da página inicial do sistema. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.menuDelete = false;
      }
    }
  }, [_vm._v(" Desistir ")]), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "color": "error darken-1",
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v(" Remover ")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }