<template>
  <div class="landing-page-faq-editor">
    <v-form class="pb-6">
      <div class="inputs pl-6 pt-6">
        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.menuTitle"
            solo
            rounded
            hide-details
            label="Menu"
            :disabled="!configurations.menuTitle.visible"
            @input="onChange('menuTitle', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.menuTitle.visible"
            inset
            class="ml-6"
            @change="toggle('menuTitle', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.title"
            solo
            rounded
            hide-details
            label="Título"
            :disabled="!configurations.title.visible"
            @input="onChange('title', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.title.visible"
            inset
            class="ml-6"
            @change="toggle('title', $event)"
          ></v-switch>
        </div>
      </div>

      <div class="d-flex flex-row-reverse pt-6">
        <v-menu
          v-model="menuDelete"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="error"
              rounded
              text
              class="text-capitalize mr-4"
              >Remover</v-btn
            >
          </template>
          <v-card max-width="300">
            <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

            <v-card-text>
              Se você continuar, esta seção será removida da página inicial do
              sistema.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                text
                small
                class="py-4"
                @click="menuDelete = false"
              >
                Desistir
              </v-btn>
              <v-btn
                color="error darken-1"
                rounded
                text
                small
                class="py-4"
                @click="remove"
              >
                Remover
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </v-form>
  </div>
</template>

<script>
import { get as _get, set as _set } from "lodash";

import { Faq } from "components/landing-page/LandingPageFaq";

export default {
  name: "LandingPageEditorFaqEditor",
  props: {
    section: {
      type: Faq,
      required: true,
    },
  },
  data: () => ({
    menuDelete: false,
    theSection: new Faq(),
    configurations: {
      menuTitle: {
        visible: true,
        value: undefined,
      },
      title: {
        visible: true,
        value: undefined,
      },
    },
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.theSection = this.section;

      this.confitureItem("menuTitle");
      this.confitureItem("title");
    },
    confitureItem(modelPath) {
      const model = _get(this.theSection, modelPath);
      const configuration = _get(this.configurations, modelPath);

      configuration.visible = typeof model === "string" && model.length > 0;
      configuration.value = model;
    },
    remove() {
      this.$emit("on-remove", this.section);
    },
    toggle(modelPath, visible) {
      const configuration = _get(this.configurations, modelPath);
      configuration.visible = visible;

      if (visible) {
        _set(this.theSection, modelPath, configuration.value);
      } else {
        _set(this.theSection, modelPath, undefined);
      }
    },
    onChange(convigurationPath, value) {
      _set(this.configurations, convigurationPath + ".value", value);
    },
  },
};
</script>
