<template>
  <div class="page--groups">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="5" lg="4" xl="3">
          <v-card rounded="lg" :loading="isSearching">
            <v-col>
              <v-row>
                <v-col class="py-7">
                  <v-text-field
                    v-if="$vuetify.breakpoint.mdAndUp"
                    ref="textSearch"
                    v-model="textToSearch"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Pesquisar Grupo"
                    autocomplete="off"
                    hide-details
                    clearable
                    solo
                    rounded
                  ></v-text-field>
                  <v-autocomplete
                    v-else
                    ref="autocompleteSearch"
                    v-model="textAutocomplete"
                    :search-input.sync="textToSearch"
                    :items="searchedItems"
                    item-text="name"
                    clearable
                    solo
                    rounded
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    autocomplete="off"
                    no-data-text="Nenhum Grupo encontrado"
                    placeholder="Pesquisar Grupo"
                    @change="onSelectSearchedItem()"
                  >
                    <template #item="data">
                      <template>
                        <v-list-item-icon>
                          <v-icon
                            v-text="data.item.icon || 'border-none-variant'"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.displayName"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-divider class="d-none d-md-flex"></v-divider>

              <v-row class="d-none d-md-flex">
                <v-col>
                  <v-responsive>
                    <v-list two-line max-height="1000" class="overflow-auto">
                      <v-list-item
                        v-for="(group, ui) in searchedItems"
                        :key="ui"
                      >
                        <v-list-item-icon>
                          <v-icon
                            v-text="group.icon || 'border-none-variant'"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="group.displayName"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="group.name"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn text rounded @click="selectGroup(group)">
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-responsive>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" md="7" lg="8" xl="9">
          <v-card
            rounded="lg"
            class="group-details"
            :class="{ 'hide-permissions': hidePermissions }"
          >
            <v-toolbar v-if="selectedItem" dense elevation="1">
              <v-chip
                class="ma-2"
                :color="statusColor(selectedItem.active)"
                text-color="white"
              >
                <v-avatar
                  left
                  class="darken-4"
                  :class="statusColor(selectedItem.active)"
                  v-text="selectedItem.position"
                ></v-avatar>
                <span v-text="statusLabel"></span>
              </v-chip>
              <v-tooltip v-if="selectedItem.locked" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="grey" class="ml-2"
                    >mdi-shield</v-icon
                  >
                </template>
                Grupo Protegido
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-menu offset-y transition="slide-y-transition">
                <template #activator="{ on, attrs }">
                  <v-btn small plain :ripple="false" v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    :disabled="selectedItem.locked"
                    @click.prevent="showEditDialog"
                  >
                    <v-list-item-avatar>
                      <v-btn icon x-small>
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Editar Grupo'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click.prevent="showCreateDialog">
                    <v-list-item-avatar>
                      <v-btn icon x-small>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Novo Grupo'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item
                    :disabled="selectedItem.locked"
                    @click.prevent="dialogDelete.show = true"
                  >
                    <v-list-item-avatar>
                      <v-btn icon x-small>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="'Remover Grupo'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>

            <v-card v-if="selectedItem" elevation="0" class="my-5">
              <div class="text-center">
                <v-icon
                  v-text="selectedItem.icon || 'border-none-variant'"
                  size="50"
                />
                <br />
                <span
                  v-text="selectedItem.displayName"
                  class="d-inline-block mt-1 font-weight-medium"
                ></span>
              </div>
            </v-card>

            <v-card class="mb-1 mb-lg-6 pa-5" rounded="lg" elevation="1">
              <v-list subheader two-line v-if="selectedItem" elevation="1">
                <v-subheader inset class="ml-0">
                  <v-icon class="mr-4">mdi-format-list-checks</v-icon>
                  <span class="break-spaces">Permissões</span>
                </v-subheader>

                <v-list-item
                  :disabled="!selectedItem"
                  v-for="item in selectedItem.permissions"
                  :key="`${item.id}`"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        class="mr-3"
                        v-if="item.icon"
                        v-text="item.icon"
                      ></v-icon>
                      <div v-if="item.off">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-text="item.displayName"
                              class="break-spaces"
                            ></span>
                            <v-icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              color="grey"
                              class="ml-2"
                              >mdi-shield-off</v-icon
                            >
                          </template>
                          Desativada
                        </v-tooltip>
                      </div>
                      <span
                        v-else
                        v-text="item.displayName"
                        class="break-spaces"
                      ></span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.name"
                      class="break-spaces"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="item.active"
                      :disabled="item.locked"
                      :loading="item.isSaving"
                      inset
                      @change="savePermissionChange(item, item.active)"
                    ></v-switch>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog.show" max-width="500" @input="closeDialog">
      <v-card rounded="lg">
        <v-card-title class="text-h5" v-text="dialog.title"> </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-5 mt-5">
          <v-form
            ref="form"
            v-model="dialog.isFormValid"
            class="px-10"
            autocomplete="off"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="dialog.data.icon"
                  :append-icon="dialog.data.icon"
                  id="permission_icon"
                  placeholder="Ícone"
                  label="Ícone"
                  dense
                  hint="Qual o ícone da permissão?"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="dialog.data.displayName"
                  id="permission_display-name"
                  placeholder="Nome de Exibição"
                  label="Nome de Exibição"
                  dense
                  hint="Qual o nome será exibido?"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="dialog.data.name"
                  id="permission_name"
                  placeholder="Nome"
                  label="Nome"
                  dense
                  hint="Qual o nome da permissão?"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mt-5">
              <v-col>
                <v-slider
                  v-model="dialog.data.position"
                  label="Posição"
                  :min="1"
                  thumb-label="always"
                  :thumb-size="24"
                  dense
                >
                </v-slider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-switch
                  v-model="dialog.data.active"
                  inset
                  dense
                  label="Ativo"
                  class="mt-0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="closeDialog"> Cancelar </v-btn>
          <v-btn color="primary" text @click="save" v-text="dialog.saveLabel">
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete.show" max-width="300" @input="closeDialog">
      <v-card max-width="300">
        <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

        <v-card-text>
          Se você continuar, todos os usuários que tiverem este grupo serão
          afetados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            text
            small
            class="py-4"
            @click="dialogDelete.show = false"
          >
            Desistir
          </v-btn>
          <v-btn
            color="error darken-1"
            rounded
            text
            small
            class="py-4"
            @click="remove(selectedItem)"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Groups",
  data: () => ({
    items: [],
    selectedItemId: undefined,
    textToSearch: undefined,
    textAutocomplete: undefined,
    isSearching: false,
    permissions: [],
    interval: undefined,
    hidePermissions: false,
    dialog: {
      show: false,
      edit: false,
      title: "",
      saveLabel: "",
      isFormValid: null,
      data: {
        id: 0,
        name: "",
        displayName: "",
        icon: "",
        active: true,
        position: 0,
      },
    },
    dialogDelete: {
      show: false,
    },
  }),
  computed: {
    /**
     * Retorna o item selecionado.
     * Por padrão retorna o primeiro item da listagem
     */
    selectedItem() {
      return this.selectedItemId
        ? this.items.find((p) => this.selectedItemId === p.id)
        : this.items[0];
    },
    /**
     * Itens filtrados.
     * Usado na listagem dinâmica da pesquisa
     */
    searchedItems() {
      const minCharacters = 3;

      if (!this.textToSearch) {
        return this.items;
      }
      const searchedText = this.textToSearch
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      if (!searchedText || searchedText.length < minCharacters) {
        return this.items;
      } else {
        return this.items.filter(
          (item) =>
            item.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(searchedText) >= 0 ||
            item.displayName
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(searchedText) >= 0
        );
      }
    },
    statusLabel() {
      return this.selectedItem && this.selectedItem.active
        ? "Ativo"
        : "Inativo";
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    showEditDialog() {
      this.dialog.show = true;
      this.dialog.edit = true;
      this.dialog.title = "Editar Grupo";
      this.dialog.saveLabel = "Salvar";

      this.dialog.data.id = this.selectedItem.id;
      this.dialog.data.icon = this.selectedItem.icon;
      this.dialog.data.name = this.selectedItem.name;
      this.dialog.data.displayName = this.selectedItem.displayName;
      this.dialog.data.active = this.selectedItem.active;
      this.dialog.data.position = this.selectedItem.position;
    },
    showCreateDialog() {
      this.dialog.show = true;
      this.dialog.edit = false;
      this.dialog.title = "Criar Grupo";
      this.dialog.saveLabel = "Criar";

      this.dialog.data.id = 0;
      this.dialog.data.icon = "";
      this.dialog.data.name = "";
      this.dialog.data.displayName = "";
      this.dialog.data.active = true;
      this.dialog.data.position = 1;
    },
    closeDialog() {
      this.dialog.edit = false;
      this.dialog.show = false;
      this.dialog.isFormValid = null;

      this.dialog.data.id = 0;
      this.dialog.data.icon = "";
      this.dialog.data.name = "";
      this.dialog.data.displayName = "";
      this.dialog.data.active = true;
      this.dialog.data.position = 0;

      this.dialogDelete.show = false;
    },
    save() {
      if (this.dialog.edit) {
        this.$atualizarGrupo(this.dialog.data.id, {
          icone: this.dialog.data.icon,
          nome: this.dialog.data.name,
          nomeDeExibicao: this.dialog.data.displayName,
          ativo: this.dialog.data.active,
          menu: this.dialog.data.menu,
          posicao: this.dialog.data.position,
        }).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.fetchData()
            .then(() => {
              this.closeDialog();
            })
            .catch(() => this.closeDialog());
        });
      } else {
        this.$criarGrupo({
          icone: this.dialog.data.icon,
          nome: this.dialog.data.name,
          nomeDeExibicao: this.dialog.data.displayName,
          ativo: this.dialog.data.active,
          menu: this.dialog.data.menu,
          posicao: this.dialog.data.position,
        }).then(({ data }) => {
          this.$notifySuccess(data.mensagem);
          this.fetchData()
            .then(() => {
              this.closeDialog();
            })
            .catch(() => this.closeDialog());
        });
      }
    },
    remove(item) {
      this.$deletarGrupo(item.id).then(({ data }) => {
        this.$notifySuccess(data.mensagem);
        this.selectedItemId = undefined;
        this.fetchData()
          .then(() => {
            this.closeDialog();
          })
          .catch(() => this.closeDialog());
      });
    },
    statusColor(active) {
      return active ? "green" : "grey";
    },
    /**
     * Chamado quando um item do autocomplete (apenas mobile) é selecionado ou limpo
     */
    onSelectSearchedItem() {
      if (this.textAutocomplete) {
        this.selectedItemId = this.items.find(
          (u) => u.name === this.textAutocomplete
        ).id;
        this.$refs.autocompleteSearch.clearableCallback(true);
      }
    },
    /**
     * Salva na API a alteração da permissão
     */
    savePermissionChange(permission, newValue) {
      permission.isSaving = true;

      this.$atualizarPermissaoDoGrupo(this.selectedItem.id, permission.id, {
        idDaPermissao: permission.id,
        ativa: permission.active,
        idDoGrupo: this.selectedItem.id,
      })
        .then(({ data }) => {
          permission.active = data.sucesso ? newValue : !newValue;
          permission.isSaving = false;
        })
        .catch(() => {
          permission.active = !newValue;
          permission.isSaving = false;
        });
    },
    /**
     * Carrega os dados da página
     */
    async fetchData() {
      this.fetchPermissions()
        .then(() => this.fetchGroups())
        .then(() => this.transformGroups())
        .then(() => {
          if (!this.selectedItemId) {
            this.selectedItemId = this.items[0].id;
          }
        })
        .then(() => this.hidePageLoading())
        .catch(() => this.hidePageLoading());
    },
    /**
     * Carrega as permissons do sistema
     */
    async fetchPermissions() {
      return this.$listarPermissoes().then(({ data }) => {
        this.permissions = data.permissoes.map((permission) => {
          return {
            id: permission.id,
            name: permission.nome,
            displayName: permission.nomeDeExibicao,
            icon: permission.icone,
            url: permission.enderecoUrl,
            active: permission.ativa,
            menu: permission.menu,
            isSaving: false,
          };
        });
      });
    },
    /**
     * Carrega os perfis cadastrados
     */
    async fetchGroups() {
      return this.$listarGrupos().then(({ data }) => {
        this.items = data.grupos.map((item) => {
          return {
            id: item.id,
            name: item.nome,
            displayName: item.nomeDeExibicao,
            icon: item.icone,
            active: item.ativo,
            position: item.posicao,
            permissions: item.permissoes,
            locked: item.protegido,
          };
        });
      });
    },
    /**
     * Seleciona o Grupo para alteração
     */
    selectGroup(group) {
      if (this.selectedItemId !== group.id) {
        this.hidePermissions = true;

        setTimeout(() => {
          this.selectedItemId = group.id;

          if (this.$vuetify.breakpoint.mdAndUp) {
            this.$refs.textSearch.clearableCallback();
          }

          setTimeout(() => {
            this.hidePermissions = false;
          }, 200);
        }, 300);
      }
    },
    /**
     * Transforma (mescla) as permissões do Grupo com base na lista principal de permissões
     */
    getTransformedGroupPermissions(group) {
      return this.permissions.map((p) => {
        return {
          ...p,
          active: group.permissions.some((pp) => pp.id === p.id),
          locked: (
            group.permissions.find((pp) => pp.id === p.id) || {
              protegida: false,
            }
          ).protegida,
          off: !p.active,
        };
      });
    },
    /**
     * Transforma as permissões de todos os perfis para permitir a correta renderização da página
     */
    async transformGroups() {
      this.items = this.items.map((group) => {
        group.permissions = this.getTransformedGroupPermissions(group);
        return group;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.thumbnail) {
  border: 1px solid #ddd;
  border-radius: 10cm;

  .v-image__image--cover {
    border: 5px solid #fff;
    border-radius: 10cm;
  }
}

.group-details {
  opacity: 1;
  transition: all 0.5s ease;
  &.hide-permissions {
    opacity: 0;
  }
}
</style>