var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "landing-page-features-editor"
  }, [_c('v-form', {
    staticClass: "pb-6"
  }, [_c('div', {
    staticClass: "inputs pl-6 pt-6"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Menu",
      "disabled": !_vm.configurations.menuTitle.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('menuTitle', $event);
      }
    },
    model: {
      value: _vm.theSection.menuTitle,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "menuTitle", $$v);
      },
      expression: "theSection.menuTitle"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('menuTitle', $event);
      }
    },
    model: {
      value: _vm.configurations.menuTitle.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.menuTitle, "visible", $$v);
      },
      expression: "configurations.menuTitle.visible"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Título",
      "disabled": !_vm.configurations.title.visible
    },
    on: {
      "input": function ($event) {
        return _vm.onChange('title', $event);
      }
    },
    model: {
      value: _vm.theSection.title,
      callback: function ($$v) {
        _vm.$set(_vm.theSection, "title", $$v);
      },
      expression: "theSection.title"
    }
  }), _c('v-switch', {
    staticClass: "ml-6",
    attrs: {
      "inset": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle('title', $event);
      }
    },
    model: {
      value: _vm.configurations.title.visible,
      callback: function ($$v) {
        _vm.$set(_vm.configurations.title, "visible", $$v);
      },
      expression: "configurations.title.visible"
    }
  })], 1), _c('br'), _c('div', [_c('div', {
    staticClass: "d-flex justify-space-between mb-3"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v(" Recursos ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "plain": "",
      "ripple": false,
      "color": "success darken-1"
    },
    on: {
      "click": function ($event) {
        _vm.featureItemDialog.display = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus-circle-outline")])], 1)], 1), _vm.theSection.features && _vm.theSection.features.length > 0 ? _c('v-simple-table', {
    attrs: {
      "fixed-header": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": _vm.theSection.features,
            "handle": ".drag"
          }
        }, _vm._l(_vm.theSection.features, function (feature, index) {
          return _c('tr', {
            key: feature.title
          }, [_c('td', {
            staticClass: "td-drag pl-0"
          }, [_c('v-btn', {
            staticClass: "drag",
            attrs: {
              "small": "",
              "plain": "",
              "ripple": false
            }
          }, [_c('v-icon', [_vm._v("mdi-drag")])], 1)], 1), _c('td', {
            staticClass: "td-title px-0",
            on: {
              "dblclick": function ($event) {
                return _vm.editFeatureItem(feature, index);
              }
            }
          }, [_c('div', [_vm._v(" " + _vm._s(feature.title) + " ")])]), _c('td', {
            staticClass: "td-actions pr-0"
          }, [_c('v-btn', {
            attrs: {
              "small": "",
              "plain": "",
              "ripple": false
            },
            on: {
              "click": function ($event) {
                return _vm.editFeatureItem(feature, index);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-square-edit-outline")])], 1), _c('v-btn', {
            attrs: {
              "small": "",
              "plain": "",
              "ripple": false
            },
            on: {
              "click": function ($event) {
                return _vm.markFeatureItemForDelete(index);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-trash-can-outline")])], 1)], 1)]);
        }), 0)];
      },
      proxy: true
    }], null, false, 1503914604)
  }) : _c('div', {
    staticClass: "text-center grey--text"
  }, [_c('small', [_vm._v("Ainda não há recursos")])]), _c('v-dialog', {
    attrs: {
      "max-width": "350"
    },
    model: {
      value: _vm.deleteFeatureItemDialog.display,
      callback: function ($$v) {
        _vm.$set(_vm.deleteFeatureItemDialog, "display", $$v);
      },
      expression: "deleteFeatureItemDialog.display"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "pb-0"
  }, [_vm._v("Tem Certeza?")]), _c('v-card-subtitle', {
    staticClass: "mt-0 pb-2"
  }), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-3"
  }, [_vm._v(" Se você continuar, este recurso será removido da página inicial do sistema. ")]), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pb-3"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.deleteFeatureItemDialog.display = false;
      }
    }
  }, [_vm._v(" Desistir ")]), _c('v-btn', {
    attrs: {
      "color": "error darken-1",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": _vm.deleteFeatureItem
    }
  }, [_vm._v(" Remover ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "350"
    },
    model: {
      value: _vm.featureItemDialog.display,
      callback: function ($$v) {
        _vm.$set(_vm.featureItemDialog, "display", $$v);
      },
      expression: "featureItemDialog.display"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "pb-0"
  }, [_vm._v("Novo Recurso ")]), _c('v-card-subtitle', {
    staticClass: "mt-0 pb-2"
  }, [_vm._v("O recurso será adicionado à grade de recursos na página inicial")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-3"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "hide-details": "",
      "label": "Título"
    },
    model: {
      value: _vm.featureItemDialog.feature.title,
      callback: function ($$v) {
        _vm.$set(_vm.featureItemDialog.feature, "title", $$v);
      },
      expression: "featureItemDialog.feature.title"
    }
  })], 1), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "solo": "",
      "hide-details": "",
      "label": "Descrição"
    },
    model: {
      value: _vm.featureItemDialog.feature.description,
      callback: function ($$v) {
        _vm.$set(_vm.featureItemDialog.feature, "description", $$v);
      },
      expression: "featureItemDialog.feature.description"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pb-3"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.featureItemDialog.display = false;
      }
    }
  }, [_vm._v(" Fechar ")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": _vm.onFeatureItemDialogSave
    }
  }, [_vm._v(" Salvar ")])], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "d-flex flex-row-reverse pt-6"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-y-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-capitalize mr-4",
          attrs: {
            "color": "error",
            "rounded": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Remover")])];
      }
    }]),
    model: {
      value: _vm.menuDelete,
      callback: function ($$v) {
        _vm.menuDelete = $$v;
      },
      expression: "menuDelete"
    }
  }, [_c('v-card', {
    attrs: {
      "max-width": "300"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Tem Certeza? ")]), _c('v-card-text', [_vm._v(" Se você continuar, esta seção será removida da página inicial do sistema. ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.menuDelete = false;
      }
    }
  }, [_vm._v(" Desistir ")]), _c('v-btn', {
    staticClass: "py-4",
    attrs: {
      "color": "error darken-1",
      "rounded": "",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v(" Remover ")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }